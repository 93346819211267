
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { getAuth } from "firebase/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = ref(getAuth().currentUser);

    const isUserAuthenticatedByFirebase = computed(() => {
      return !!getAuth().currentUser;
    });

    const isUserAuthenticatedBySpotify = computed(() => {
      return !!store.getters["SpotifyAccessTokenHelperModule/getAccessToken"];
    });

    const spotifyUser = computed(() => {
      return store.getters["SpotifyAccessTokenHelperModule/getSpotifyUser"];
    });

    const logout = async () => {
      const path = isUserAuthenticatedByFirebase.value
        ? "/sign-in"
        : "/discover";
      await store.dispatch("UserModule/logoutUser");
      await store.dispatch("UserModule/resetStore");
      await store.dispatch("DiscoverModule/resetStore");
      await store.dispatch("EventsModule/resetStore");
      await store.dispatch("SpotifyAccessTokenHelperModule/resetStore");
      await router.push(path);
    };
    return {
      logout,
      user,
      spotifyUser,
      isUserAuthenticatedByFirebase,
      isUserAuthenticatedBySpotify,
    };
  },
});
