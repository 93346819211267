<template>
  <div class="search-bar">
    <input
      class="form-control form-control-solid me-3"
      placeholder="Artist, Song, Playlist, Genre"
      v-model="searchQuery"
      v-on:keyup.enter="searchArtist"
    />
    <svg
      class="search-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      v-on:click="searchArtist"
    >
      <path
        d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
      />
    </svg>
  </div>
  <button class="btn btn-sm btn-primary btn-style" v-on:click="searchArtist">
    Search
  </button>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { computed, ref, watch } from "vue";
export default {
  name: "SearchBar2",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const searchQueryURL = computed(() => {
      return route.query.search;
    });
    const searchQuery = ref(null);
    const searchArtist = () => {
      if (searchQuery.value) {
        router.push(`/artistsV2?search=${searchQuery.value}`);
      }
    };
    watch(
      () => searchQueryURL.value,
      (currentValue) => {
        searchQuery.value = currentValue;
      },
      { immediate: true }
    );
    return {
      searchQuery,
      searchArtist,
    };
  },
};
</script>
<style scoped>
input::placeholder {
  color: #33647f !important;
  font-size: 12px;
  font-weight: 400;
}
.search-bar {
  position: relative;
  width: -webkit-fill-available;
  margin-right: 1rem;
}
.search-icon {
  position: absolute;
  right: 2%;
  font-size: 24px;
  bottom: 25%;
  fill: #33647f;
}
.btn-style {
  margin-left: 12px;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.search-icon:hover {
  color: #009ef7 !important;
}
</style>
