
import { computed, defineComponent, ref } from "vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { getAuth } from "firebase/auth";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const user = ref(getAuth().currentUser);
    const store = useStore();

    const isUserAuthenticatedByFirebase = computed(() => {
      return !!getAuth().currentUser;
    });

    const isUserAuthenticatedBySpotify = computed(() => {
      return !!store.getters["SpotifyAccessTokenHelperModule/getAccessToken"];
    });

    const spotifyUser = computed(() => {
      return store.getters["SpotifyAccessTokenHelperModule/getSpotifyUser"];
    });

    return {
      user,
      spotifyUser,
      isUserAuthenticatedByFirebase,
      isUserAuthenticatedBySpotify,
    };
  },
});
