<template>
  <!--begin::Modal - Create Api Key-->
  <button
    ref="watchListModalButton"
    hidden
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#create_watchlist_modal"
  ></button>
  <div
    class="modal fade"
    id="create_watchlist_modal"
    tabindex="-1"
    aria-hidden="true"
    role="dialog"
    aria-labelledby="createForecastModal"
    data-backdrop="static"
    data-keyboard="false"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_create_api_key_header">
          <!--begin::Modal title-->
          <h2>
            {{
              showCreateWatchlistForm
                ? "Create Watchlist"
                : "Add to Existing Watchlist"
            }}
          </h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <div
          v-show="watchlist.length && !showCreateWatchlistForm"
          class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15"
          v-loading="dataLoading"
        >
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <!--end::Title-->

            <!--begin::Description-->
            <div class="text-gray-400 fw-bold fs-5 mt-5">
              If you want to create a new watchlist, then
              <a
                type="button"
                @click="showCreateWatchlistForm = true"
                class="link-primary fw-bolder"
                >Click Here</a
              >.
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->

          <!--begin::Users-->
          <div class="mb-15">
            <!--begin::List-->
            <div class="mh-375px scroll-y me-n7 pe-7">
              <template v-for="(watchlist, index) in watchlist" :key="index">
                <!--begin::User-->
                <div
                  class="
                    d-flex
                    flex-stack
                    py-5
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <!--begin::Details-->
                  <div class="d-flex align-items-center">
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle">
                      <img
                        v-if="watchlist.avatar"
                        alt="Pic"
                        :src="watchlist.avatar"
                      />
                      <span v-else class="symbol-label fw-bold">
                        {{ watchlist.name.charAt(0) }}
                      </span>
                    </div>
                    <!--end::Avatar-->

                    <!--begin::Details-->
                    <div
                      class="ms-6"
                      data-toggle="tooltip"
                      :title="
                        isArtistAlreadyAddedInWatchlist(
                          watchlist.watchListArtists
                        )
                          ? 'Artist already added'
                          : 'Add to watchlist'
                      "
                    >
                      <!--begin::Name-->
                      <a
                        type="button"
                        @click="addArtistToWatchlist(watchlist.id)"
                        class="
                          d-flex
                          align-items-center
                          fs-5
                          fw-bolder
                          text-dark text-hover-primary
                          cursor-pointer
                        "
                        :class="{
                          disabledLink: isArtistAlreadyAddedInWatchlist(
                            watchlist.watchListArtists
                          ),
                        }"
                      >
                        {{ watchlist.name }}
                      </a>
                      <!--end::Name-->

                      <!--begin::Email-->
                      <div class="fw-bold text-gray-400">
                        {{ new Date(watchlist.created_at).toDateString() }}
                      </div>
                      <!--end::Email-->
                    </div>
                    <!--end::Details-->
                  </div>
                  <!--end::Details-->

                  <!--end::Stats-->
                </div>
                <!--end::User-->
              </template>
            </div>
            <!--end::List-->
          </div>
        </div>

        <!--begin::Form-->
        <Form
          ref="formRef"
          v-show="!watchlist.length || showCreateWatchlistForm"
          class="form"
          :validation-schema="validationSchema"
        >
          <!--begin::Modal body-->
          <div class="modal-body py-2 px-lg-17">
            <a
              type="button"
              @click="showCreateWatchlistForm = false"
              class="link-primary fw-bolder"
              >&#8592; Back</a
            >
            <!--begin::Scroll-->
            <div
              class="scroll-y me-n7 pe-7 pt-10 pb-10"
              id="kt_modal_create_api_key_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
              data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
              data-kt-scroll-offset="300px"
            >
              <!--begin::Input group-->
              <div class="mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Watchlist Name</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Your Watchlist Name"
                  name="watchlistName"
                  v-model="watchlistName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="watchlistName" />
                  </div>
                </div>
                <!--end::Input-->
              </div>

              <!--begin::Input group-->
              <div class="mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Tags</label>
                <!--end::Label-->

                <el-form-item prop="tags" class="watch-lists-tags">
                  <el-select
                    class="w-100"
                    v-model="watchListTags"
                    multiple
                    filterable
                    allow-create
                    placeholder="Choose tags for your target"
                  >
                    <el-option label="Important" value="important"></el-option>
                    <el-option label="high" value="high"></el-option>
                    <el-option label="low" value="low"></el-option>
                    <el-option label="medium" value="medium"></el-option>
                  </el-select>
                </el-form-item>
                <!--end::Input-->
              </div>

              <!--end::Input group-->
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              ref="addWatchlistModalCloseButton"
              type="reset"
              class="btn btn-white me-3"
              data-bs-toggle="modal"
              data-bs-target="#create_watchlist_modal"
            >
              Close
            </button>
            <button
              @click="createNewWatchlist"
              ref="submitButtonRef"
              type="submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "CreateWatchListModal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButtonRef = ref(null);
    const modalFlag = ref(false);
    const store = useStore();
    const route = useRoute();
    const formRef = ref(null);
    const watchListModalButton = ref(null);
    const watchlist = computed(() => store.getters["UserModule/getWatchlist"]);
    const watchlistName = ref("");
    const watchListTags = ref([]);
    const showCreateWatchlistForm = ref(false);
    const dataLoading = ref(false);
    const artistId = ref("");
    const addWatchlistModalCloseButton = ref(null);

    const validationSchema = Yup.object().shape({
      watchlistName: Yup.string().required().label("Watchlist Name"),
    });

    const uuidComputed = computed(() => {
      return store.getters["ArtistModule/getUUIDToShowWatchListModal"];
    });

    const createNewWatchlist = async ($event) => {
      $event.preventDefault();
      if (watchlistName.value && watchlistName.value !== "") {
        try {
          //Disable button
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");

          const watchlistTagsArray = [];
          for (const tags of watchListTags.value) {
            watchlistTagsArray.push({
              name: tags,
            });
          }

          const watchlistID = await store.dispatch("UserModule/addWatchlist", {
            name: watchlistName.value,
            watchListTags: watchlistTagsArray,
          });
          if (watchlistID && artistId.value) {
            ElNotification({
              title: "Watch list added",
              message: "Watch list added successfully",
              type: "success",
            });
            await addArtistToWatchlist(watchlistID);
          }
          addWatchlistModalCloseButton.value.click();
        } catch (e) {
          console.error(e);
          ElNotification({
            title: "Record already exists",
            message: "Watch list already exist with same name",
            type: "error",
          });
        }
        submitButtonRef.value.removeAttribute("data-kt-indicator");
        submitButtonRef.value.disabled = false;
      }
    };

    const addArtistToWatchlist = async (watchlistID) => {
      try {
        store
          .dispatch("UserModule/addArtistToWatchlist", {
            artistID: artistId.value,
            watchlistID: watchlistID,
          })
          .then(() => {
            ElNotification({
              title: "Artist added",
              message: "Artist added to watchlist successfully",
              type: "success",
            });
          });
      } catch (e) {
        console.error(e);
        dataLoading.value = false;
      }
      addWatchlistModalCloseButton.value.click();
    };

    const isArtistAlreadyAddedInWatchlist = (artists) => {
      const isArtistPresent = artists.filter(
        (artist) => artist.artist.uuid === artistId.value
      );
      return !!isArtistPresent.length;
    };

    const showModal = () => {
      watchListModalButton.value.click();
    };

    async function fetchWatchlist(uuid) {
      if (uuid) {
        artistId.value = uuid;
      } else {
        artistId.value = route.params.uuid;
      }
      resetModalToOriginalState();
      try {
        if (!watchlist.value.length) {
          dataLoading.value = true;
          await store.dispatch("UserModule/fetchWatchlist");
          dataLoading.value = false;
        }
      } catch (e) {
        console.error(e);
        dataLoading.value = false;
      }
    }

    const resetModalToOriginalState = () => {
      showCreateWatchlistForm.value = false;
      watchlistName.value = "";
      watchListTags.value = [];
      formRef.value.resetForm();
    };

    watch(
      () => uuidComputed.value,
      async (value) => {
        if (value) {
          await showModal();
          await fetchWatchlist(value);
          await store.commit(
            "ArtistModule/SET_SHOW_WATCHLIST_MODAL_ARTIST_UUID",
            ""
          );
        }
      },
      { immediate: true }
    );

    return {
      formRef,
      artistId,
      validationSchema,
      submitButtonRef,
      modalFlag,
      watchlist,
      watchListTags,
      showCreateWatchlistForm,
      watchlistName,
      dataLoading,
      watchListModalButton,
      addWatchlistModalCloseButton,
      fetchWatchlist,
      addArtistToWatchlist,
      createNewWatchlist,
      isArtistAlreadyAddedInWatchlist,
      resetModalToOriginalState,
      showModal,
    };
  },
});
</script>
<style lang="scss">
.disabledLink {
  pointer-events: none;
}

.watch-lists-tags {
  .el-select__input {
  }

  .el-input__inner {
    height: 42px !important;
    background-color: #f5f8fa;
    border: none !important;
    border-radius: 0.475rem !important;
  }

  .el-tag {
    background-color: white !important;
  }
}
</style>
