<template>
  <!--begin::Exolore drawer-->
  <div
    class="viewArtistDrawer"
    id="artistViewDrawer"
    data-kt-drawer="true"
    data-kt-drawer-name="artistDrawerComponent"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    :data-kt-drawer-width="drawerWidth"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_explore_toggle"
    data-kt-drawer-close="#kt_explore_close"
  >
    <div class="w-100">
      <el-row :gutter="24">
        <el-col :span="24">
          <el-card v-loading="loading.artistDataLoading" class="box-card">
            <span
              @click="hideDrawer"
              class="
                svg-icon svg-icon-1
                p-2
                btn btn-md btn-icon btn-active-color-primary
                close-icon
              "
            >
              <inline-svg
                class="svg-icon"
                src="media/icons/duotune/arrows/arr061.svg"
              />
            </span>
            <div class="drawer-header">
              <div class="user-details">
                <el-avatar :size="80" :src="artist.imageUrl"></el-avatar>
                <h4 class="m-lg-5 drawer-heading">{{ artist.name }}</h4>
              </div>
              <div class="drawer-header-buttons">
                <!--                <Follow-->
                <!--                  v-model:isFollowed="artist.isFollowed"-->
                <!--                  :uuid="artist.uuid"-->
                <!--                  :is-followed="artist.isFollowed"-->
                <!--                />-->
                <button
                  @click="routeToArtistDetailPage(artist.uuid)"
                  class="btn btn-sm btn-primary me-3"
                >
                  Details
                </button>
                <button
                  @click="routeToCrawlPage(artist.name)"
                  class="btn btn-sm btn-light me-3"
                >
                  Crawl
                </button>
                <ArtistMenuComponent
                  :artist="artist"
                  :from-drawer="true"
                  :buttons="[
                    'discover',
                    'watchlist',
                    'crm',
                    'saveArtist',
                    'Follow',
                    'aiSandbox',
                  ]"
                />
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!--      <el-row class="row-info" :gutter="24">-->
      <!--        <el-col :md="8" :sm="24">-->
      <!--          <social-views :uuid="artist.uuid" platform="facebook" />-->
      <!--        </el-col>-->
      <!--        <el-col :md="8" :sm="24">-->
      <!--          <social-views :uuid="artist.uuid" platform="instagram" />-->
      <!--        </el-col>-->
      <!--        <el-col :md="8" :sm="24">-->
      <!--          <social-views :uuid="artist.uuid" platform="twitter" />-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <el-row class="row-info" :gutter="24">
        <el-col :span="24">
          <div class="card">
            <div class="card-body">
              <ArtistHeaderOverView
                :uuid="artist.uuid"
                :total-fanbase="totalFanbase"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-info" :gutter="24">
        <el-col :span="24">
          <SocialAnalytics :uuid="artist.uuid" platform="spotify" />
        </el-col>
        <el-col :span="24">
          <FanbaseOvertime
            :show-de-select-button="false"
            :uuid="artist.uuid"
            :platforms="['instagram', 'facebook', 'twitter']"
          />
          <!--          <SocialAnalytics :uuid="artist.uuid" platform="youtube" />-->
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent.ts";
// import SocialViews from "@/artists/components/SocialViews";
import SocialAnalytics from "@/artists/components/SocialAnalytics";
import {
  getAllPlatformFanDistribution,
  getArtistByUUID,
} from "@/api/artists.api";
import { isMobileOnly, isTablet } from "mobile-device-detect";
import Follow from "../../common/components/Follow.vue";
import emitter from "tiny-emitter/instance";
import { ref, onMounted } from "vue";
import { updateUserSearchHistory } from "@/api/user.api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ArtistHeaderOverView from "@/artists/components/ArtistHeaderOverView";
import FanbaseOvertime from "@/artists/components/FanbaseOvertime";
import ArtistMenuComponent from "@/artists/components/ArtistMenuComponent";
export default {
  name: "ArtistProfileDrawer",
  components: {
    ArtistMenuComponent,
    FanbaseOvertime,
    ArtistHeaderOverView,
    // SocialViews,
    SocialAnalytics,
    // Follow,
  },
  setup() {
    const drawerWidth = ref("");
    const addWatchlistModal = ref(null);
    const artist = ref({
      uuid: "",
    });
    const router = useRouter();
    const store = useStore();
    const totalFanbase = ref(0);
    const loading = ref({
      artistDataLoading: false,
      followLoader: false,
    });
    if (isMobileOnly) {
      drawerWidth.value = '{ default: "100%" }';
    } else if (isTablet) {
      drawerWidth.value = '{ default: "70%" }';
    } else {
      drawerWidth.value = '{ default: "60%" }';
    }
    // DrawerComponent.reinitialization();
    const showDrawer = () => {
      DrawerComponent.getInstance("artistViewDrawer").show();
      DrawerComponent.getInstance("artistViewDrawer").element.scrollTop = 0;
    };
    const hideDrawer = () => {
      DrawerComponent.getInstance("artistViewDrawer").hide();
    };

    // const showModal = () => {
    //   addWatchlistModal.value.showModal();
    //   addWatchlistModal.value.fetchWatchlist(artist.value.uuid);
    // };

    const routeToCrawlPage = (artistName) => {
      window.open(
        `/crawler?search=${artistName}${
          artist.value.uuid ? "&uuid=" + artist.value.uuid : ""
        }`,
        "_blank"
      );
    };

    const viewArtist = (uuid) => {
      updateUserSearchHistory(uuid);
      artist.value = {
        uuid,
      };
      getArtistDetailsByUUID();
      getTotolFanbase();
      showDrawer();
    };

    const getArtistDetailsByUUID = async () => {
      const uuid = artist.value.uuid;
      artist.value = { uuid };
      loading.value.artistDataLoading = true;
      try {
        const { data } = await getArtistByUUID(artist.value.uuid);
        artist.value = data;
      } catch (error) {
        console.error(error);
      }
      loading.value.artistDataLoading = false;
    };

    const routeToArtistDetailPage = () => {
      hideDrawer();
      window.open(`/artists/${artist.value.uuid}/overview`, "_blank");
    };

    const getTotolFanbase = async () => {
      try {
        const {
          data: { totalFollowers },
        } = await getAllPlatformFanDistribution(artist.value.uuid);
        totalFanbase.value = totalFollowers;
      } catch (e) {
        //
      }
    };

    const routeToDiscoverPage = (artistName) => {
      hideDrawer();
      store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      // store.dispatch("EventsModule/searchArtist", artistName);
      router.push(`/discover?search=${artistName}`);
    };

    onMounted(() => {
      DrawerComponent.reinitialization();
      emitter.on("openArtistProfileDrawer", (uuid) => {
        viewArtist(uuid);
      });
    });
    return {
      loading,
      artist,
      addWatchlistModal,
      drawerWidth,
      totalFanbase,
      showDrawer,
      hideDrawer,
      routeToArtistDetailPage,
      routeToCrawlPage,
      routeToDiscoverPage,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .drawer-header {
    flex-direction: column;
  }
  .user-details {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .drawer-header {
    flex-direction: row;
    justify-content: space-between;
  }
}
.viewArtistDrawer {
  background-color: #f5f8fa;
  .box-card {
    .close-icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .drawer-header {
      display: flex;
      .user-details {
        display: flex;
        align-items: center;
      }
      .drawer-header-buttons {
        display: flex;
        align-self: center;
      }
    }
  }
  .card-box {
    width: 100%;
  }
  .row-info {
    margin: 20px 10px 0 10px !important;
  }

  .svg-icon {
    height: 1.6rem !important;
  }
}
</style>
