import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!
  const _component_ArtistProfileDrawer = _resolveComponent("ArtistProfileDrawer")!
  const _component_CreateWatchListModal = _resolveComponent("CreateWatchListModal")!
  const _component_CreateTracksWishlistModalV2 = _resolveComponent("CreateTracksWishlistModalV2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (
        _ctx.asideEnabled &&
        _ctx.isUserAuthenticatedByFirebase &&
        _ctx.$route.meta.version &&
        _ctx.$route.meta.version === 'v1'
      )
        ? (_openBlock(), _createBlock(_component_KTAside, {
            key: 0,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        id: "kt_wrapper",
        class: _normalizeClass([
        { 'p-l-0': !_ctx.isUserAuthenticatedByFirebase },
        'd-flex flex-column flex-row-fluid wrapper',
        _ctx.$route.meta.version && _ctx.$route.meta.version === 'v2'
          ? 'full-layout-size'
          : '',
      ])
      }, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
        _createElementVNode("div", {
          id: "kt_content",
          class: _normalizeClass([
          'content d-flex flex-column flex-column-fluid',
          _ctx.$route.meta.version && _ctx.$route.meta.version === 'v2'
            ? 'content-v2 container'
            : '',
        ])
        }, [
          (_openBlock(), _createBlock(_component_router_view, { key: _ctx.key }))
        ], 2),
        _createVNode(_component_KTFooter)
      ], 2)
    ]),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTDrawerMessenger),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp),
    _createVNode(_component_ArtistProfileDrawer),
    _createVNode(_component_CreateWatchListModal),
    _createVNode(_component_CreateTracksWishlistModalV2)
  ], 64))
}