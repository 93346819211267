const AdminDocMenuConfig = [
  {
    heading: "Market Intelligence",
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard-old",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Artists",
        route: "/artists",
        svgIcon: "/media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "AI Artists",
        route: "/aiArtists",
        svgIcon: "/media/icons/duotune/general/gen032.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "AI Catalogues",
        route: "/ai-catalogues",
        svgIcon: "/media/icons/duotune/general/gen032.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Watchlists",
        route: "/watchlist",
        svgIcon: "/media/icons/duotune/general/gen056.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Discover",
        route: "/discover",
        svgIcon: "/media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "AI Beta Bench",
        route: "/ai-beta-bench",
        svgIcon: "/media/icons/duotune/general/gen017.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Crawler",
        route: "/crawler-history",
        svgIcon: "/media/icons/duotune/general/gen012.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "AI Genre",
        route: "/aiGenre",
        svgIcon: "/media/icons/duotune/general/gen032.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Accounts Management",
        route: "/account-management",
        svgIcon: "/media/icons/duotune/general/gen004.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Schedulers",
        route: "/schedulers",
        svgIcon: "/media/icons/duotune/general/gen004.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
  {
    heading: "Follow Artists",
  },
];

export default AdminDocMenuConfig;
