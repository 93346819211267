<template>
  <div class="followed-artists">
    <div
      v-loading="loaders[index]"
      class="artist d-flex justify-content-between"
      v-for="(item, index) in followedArtists"
      :key="item.id"
    >
      <div class="d-flex align-items-center">
        <div
          class="d-flex align-items-center"
          @click="openArtistProfileDrawer(item.uuid)"
        >
          <el-avatar
            class="artist-avatar"
            size="small"
            shape="circle"
            :src="item.imageUrl"
          ></el-avatar>
          <h3 class="artist-name menu-title">{{ item.name }}</h3>
        </div>
      </div>

      <span
        class="svg-icon svg-icon-1"
        @click="unfollowArtist(index, item.uuid)"
      >
        <inline-svg
          class="svg-icon"
          src="/media/icons/duotune/general/gen040.svg"
        />
      </span>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";

export default {
  setup() {
    const store = useStore();
    const loaders = ref([]);

    onMounted(async () => {
      await store.dispatch("UserModule/fetchFollowedArtists");
    });

    const followedArtists = computed(
      () => store.getters["UserModule/getFollowedArtists"]
    );

    const unfollowArtist = async (index, artistID) => {
      loaders.value[index] = true;
      try {
        await store.dispatch("UserModule/unfollowArtist", artistID);
      } catch (e) {
        console.error(e);
      }
      loaders.value[index] = false;
    };
    return {
      loaders,
      followedArtists,
      unfollowArtist,
    };
  },
};
</script>
<style lang="scss" scoped>
.followed-artists {
  padding-left: 25px;
  padding-right: 25px;
  .artist {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    cursor: pointer;
  }
  .artist-name {
    color: #3f4254;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 0.3rem;
  }
  .artist-name:hover {
    color: #009ef7;
  }
  .artist-avatar {
    width: 2rem;
    margin-right: 0.5rem;
  }
}
</style>
