<template>
  <div class="top-menu-list ms-15">
    <ul class="list-inline mt-4">
      <li class="list-inline-item me-10">
        <a class="social-icon text-xs-center" @click="redirectToDashboard">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="list-icon"
          >
            <path
              d="M20 7.093v-5.093h-3v2.093l3 3zm4 5.907l-12-12-12 12h3v10h7v-5h4v5h7v-10h3zm-5 8h-3v-5h-8v5h-3v-10.26l7-6.912 7 6.99v10.182z"
            />
          </svg>
        </a>
      </li>
      <li class="list-inline-item me-10">
        <el-dropdown>
          <span class="social-icon text-xs-center el-dropdown-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="list-icon"
            >
              <path
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 17h-12v-2h12v2zm0-4h-12v-2h12v2zm0-4h-12v-2h12v2z"
              />
            </svg>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="dropdown-menu-v2">
              <el-dropdown-item @click="redirectToUrl('/wishlists')"
                >Wishlists
              </el-dropdown-item>
              <el-dropdown-item @click="redirectToUrl('/funds')"
                >Funds
              </el-dropdown-item>
              <el-dropdown-item @click="redirectToUrl('/genres')"
                >Genre
              </el-dropdown-item>
              <el-dropdown-item
                @click="redirectToUrl('/search-tracks-by-labels')"
                >Labels</el-dropdown-item
              >
              <el-dropdown-item @click="redirectToUrl('/charts')"
                >Charts
              </el-dropdown-item>
              <el-dropdown-item>Playlists (coming soon)</el-dropdown-item>
              <el-dropdown-item
                @click="redirectToUrl('/ai-beta-bench/search-results')"
                >AI Beta Bench
              </el-dropdown-item>
              <el-dropdown-item @click="redirectToUrl('/aiGenre')"
                >AI Genre Map
              </el-dropdown-item>
              <el-dropdown-item @click="redirectToUrl('/aiArtists')"
                >AI Artists
              </el-dropdown-item>
              <el-dropdown-item @click="redirectToUrl('/ai-catalogues')"
                >AI Catalogues
              </el-dropdown-item>
              <el-dropdown-item @click="redirectToUrl('/search-new-artists')"
                >Search New Artists
              </el-dropdown-item>
              <el-dropdown-item
                @click="
                  ArtistUUID && $route.path.includes('/ai-artist')
                    ? redirectToUrl('/artists/' + ArtistUUID + '/overview')
                    : redirectToUrl('/dashboard-old')
                "
                >Old UI
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li class="list-inline-item me-10">
        <a class="social-icon text-xs-center" target="_blank" href="#"
          >Artists</a
        >
      </li>
      <li class="list-inline-item me-10">
        <a class="social-icon text-xs-center" target="_blank" href="/genres"
          >Genre</a
        >
      </li>
      <li class="list-inline-item me-10">
        <a class="social-icon text-xs-center" target="_blank" href="/charts"
          >Charts</a
        >
      </li>
      <li class="list-inline-item me-10">
        <a class="social-icon text-xs-center" target="_blank" href="#"
          >Settings</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "topMenuList",
  setup() {
    const router = useRouter();
    const store = useStore();
    const redirectToDashboard = () => {
      router.push("/dashboard");
    };
    const ArtistUUID = computed(
      () => store.getters["ArtistModule/getArtistUUID"]
    );
    const redirectToUrl = (url) => {
      window.open(`${url}`, "_blank");
    };

    return { redirectToDashboard, ArtistUUID, redirectToUrl };
  },
};
</script>
<style scoped lang="scss">
.top-menu-list {
  .list-inline {
    //margin-top: 1rem !important;
    .list-inline-item {
      //margin-right: 3rem !important;
      .social-icon {
        color: #33647f !important;
        font-size: 12px;
        font-weight: 600;

        .list-icon {
          font-size: 2rem;
          fill: #33647f;
        }
      }

      .social-icon:hover {
        color: #009ef7 !important;
      }

      .list-icon:hover {
        fill: #009ef7 !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .top-menu-list {
    .list-inline {
      .me-10 {
        margin-right: 1rem !important;
      }
    }
  }
}

.el-dropdown-menu__item:hover {
  color: #009ef7 !important;
}
</style>
