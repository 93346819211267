
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import FollowedArtists from "../../user/components/FollowedArtists.vue";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import AdminMainMenuConfig from "@/core/config/AdminMainMenuConfig";
import { useStore } from "vuex";
import { Category } from "@/common/enums/categoryEnum";
export default defineComponent({
  name: "kt-menu",
  components: {
    FollowedArtists,
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();
    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });
    const userInfo = computed(() => store.getters["UserModule/getUserInfo"]);

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      AdminMainMenuConfig,
      userInfo,
      Category,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
