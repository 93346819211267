<template>
  <input
    :disabled="
      (!isUserAuthenticatedByFirebase && $route.name !== 'discover') ||
      (!isUserAuthenticatedBySpotify && $route.name === 'discover') ||
      $route.name === 'crawler'
    "
    class="form-control form-control-solid me-3"
    placeholder="Search for an artist..."
    v-model="searchQuery"
    v-on:keyup.enter="searchArtist"
  />
  <button
    v-if="isUserAuthenticatedByFirebase"
    :disabled="searchQuery === '' || $route.name === 'crawler'"
    class="btn btn-sm btn-primary me-3"
    @click="searchArtistByButtonClick('/artists')"
  >
    Analytics
  </button>
  <button
    :disabled="
      searchQuery === '' ||
      ($route.name === 'discover' && !isUserAuthenticatedBySpotify) ||
      $route.name === 'crawler'
    "
    class="btn btn-sm btn-primary me-3"
    @click="searchArtistByButtonClick('/discover')"
  >
    Discover
  </button>
  <!--  <button-->
  <!--    :disabled="-->
  <!--      searchQuery === '' ||-->
  <!--      ($route.name === 'discover' && !isUserAuthenticatedBySpotify) ||-->
  <!--      $route.name === 'crawler'-->
  <!--    "-->
  <!--    class="btn btn-sm btn-primary me-3"-->
  <!--    @click="searchArtistByButtonClick('/crawler')"-->
  <!--  >-->
  <!--    Crawl-->
  <!--  </button>-->
</template>
<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { getAuth } from "firebase/auth";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const storeSearchQuery = computed(() => {
      return store.getters["EventsModule/getArtistSearchQuery"];
    });

    const searchQueryURL = computed(() => {
      return route.query.search;
    });
    const searchQuery = ref(null);

    const searchArtistByButtonClick = (goto) => {
      if (goto === "/discover") {
        store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      }
      // store.dispatch("EventsModule/searchArtist", searchQuery.value);
      router.push(`${goto}?search=${searchQuery.value}`);
    };

    const searchArtist = () => {
      router.push(`/artistsV2?search=${searchQuery.value}`);
      // const currentRouteName = router.currentRoute.value.name;
      // if (currentRouteName === "artists") {
      //   router.push(`/artists?search=${searchQuery.value}`);
      //   return;
      // }
      // if (currentRouteName === "discover") {
      //   if (searchQuery.value === "") {
      //     store.dispatch("DiscoverModule/changeSelectedMenu", "");
      //   } else {
      //     store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      //     router.push(`/discover?search=${searchQuery.value}`);
      //   }
      //   return;
      // }
      // if (
      //   currentRouteName === "crawler" ||
      //   currentRouteName === "crawlerHistory"
      // ) {
      //   router.push(`/crawler?search=${searchQuery.value}`);
      //   return;
      // }
      // router.push(`/artists?search=${searchQuery.value}`);
      // if (currentRouteName !== "artists") {
      //   if (currentRouteName !== "discover") {
      //     router.push("/artists");
      //   } else {
      //     store.dispatch("EventsModule/searchArtist", searchQuery.value);
      //     if (searchQuery.value === "") {
      //       store.dispatch("DiscoverModule/changeSelectedMenu", "");
      //     } else {
      //       store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      //     }
      //   }
      // }
    };

    const isUserAuthenticatedByFirebase = computed(() => {
      return !!getAuth().currentUser;
    });

    const isUserAuthenticatedBySpotify = computed(() => {
      return !!store.getters["SpotifyAccessTokenHelperModule/getAccessToken"];
    });

    watch(
      () => searchQueryURL.value,
      (currentValue) => {
        searchQuery.value = currentValue;
      },
      { immediate: true }
    );
    return {
      searchQuery,
      searchArtist,
      searchArtistByButtonClick,
      isUserAuthenticatedByFirebase,
      isUserAuthenticatedBySpotify,
    };
  },
};
</script>

<style lang="scss" scoped>
.advanced-search {
  white-space: nowrap;
}
</style>
