<template>
  <button
    ref="wishListModalButton"
    hidden
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#create_tracks_wishlist_modal"
  ></button>
  <div
    class="modal fade"
    id="create_tracks_wishlist_modal"
    tabindex="-1"
    aria-hidden="true"
    role="dialog"
    aria-labelledby="createTracksWishListModal"
    data-backdrop="static"
    data-keyboard="false"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_create_api_key_header">
          <!--begin::Modal title-->
          <h2>
            {{
              showCreateWishlistForm
                ? "Create Wishlist"
                : "Add to Existing Wishlist"
            }}
          </h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <div
          v-show="wishlists.length && !showCreateWishlistForm"
          class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15"
          v-loading="dataLoading"
        >
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <!--end::Title-->

            <!--begin::Description-->
            <div class="text-gray-400 fw-bold fs-5 mt-5">
              If you want to create a new wishlist, then
              <a
                type="button"
                @click="showCreateWishlistForm = true"
                class="link-primary fw-bolder"
                >Click Here</a
              >.
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->

          <!--begin::Users-->
          <div class="mb-15">
            <!--begin::List-->
            <div class="mh-375px scroll-y me-n7 pe-7">
              <template v-for="(wishlist, index) in wishlists" :key="index">
                <!--begin::User-->
                <div
                  class="
                    d-flex
                    flex-stack
                    py-5
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <!--begin::Details-->
                  <div class="d-flex align-items-center">
                    <!--begin::Avatar-->
                    <div class="symbol symbol-35px symbol-circle">
                      <span class="symbol-label fw-bold">
                        {{ capitalizeFirstLetter(wishlist.name.charAt(0)) }}
                      </span>
                    </div>
                    <!--end::Avatar-->

                    <!--begin::Details-->
                    <div class="ms-6">
                      <!--begin::Name-->
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Click to add tracks in wishlist"
                        placement="top-start"
                      >
                        <a
                          type="button"
                          @click="addTracksToWishlist(wishlist)"
                          class="
                            d-flex
                            align-items-center
                            fs-5
                            fw-bolder
                            text-dark text-hover-primary
                            cursor-pointer
                          "
                        >
                          {{ capitalizeFirstLetter(wishlist.name) }}
                        </a>
                      </el-tooltip>
                      <!--end::Name-->

                      <!--begin::Email-->
                      <div class="fw-bold text-gray-400">
                        {{ new Date(wishlist.createdAt).toDateString() }}
                      </div>
                      <!--end::Email-->
                    </div>
                    <!--end::Details-->
                  </div>
                  <!--end::Details-->

                  <!--end::Stats-->
                </div>
                <!--end::User-->
              </template>
            </div>
            <!--end::List-->
          </div>
        </div>
        <!--begin::Form-->
        <Form
          ref="formRef"
          v-show="!wishlists.length || showCreateWishlistForm"
          class="form"
          :validation-schema="validationSchema"
        >
          <!--begin::Modal body-->
          <div class="modal-body py-2 px-lg-17">
            <a
              type="button"
              @click="showCreateWishlistForm = false"
              class="link-primary fw-bolder"
              >&#8592; Back</a
            >
            <!--begin::Scroll-->
            <div
              class="scroll-y me-n7 pe-7 pt-10 pb-10"
              id="kt_modal_create_api_key_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
              data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
              data-kt-scroll-offset="300px"
            >
              <!--begin::Input group-->
              <div class="mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Wishlist Name</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Your Wishlist Name"
                  name="wishlistName"
                  v-model="wishlistName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="wishlistName" />
                  </div>
                </div>
                <!--end::Input-->
              </div>

              <div class="fv-row mb-7">
                <label class="form-label fw-bolder text-dark fs-6"
                  >Status</label
                >
                <br />
                <el-switch
                  v-model="isPublic"
                  class="mt-2"
                  active-text="Public"
                  inactive-text="Private"
                  style="
                    --el-switch-on-color: #13ce66;
                    --el-switch-off-color: #ff4949;
                  "
                />
              </div>
              <div class="fv-row mb-7">
                <label class="form-label fw-bolder text-dark fs-6"
                  >Fund Slot</label
                >
                <br />
                <el-switch
                  v-model="fundSlot"
                  class="mt-2"
                  active-text="Acquired catalogues"
                  inactive-text="Equity Catalogues"
                  style="
                    --el-switch-on-color: #13ce66;
                    --el-switch-off-color: #ff4949;
                  "
                />
              </div>
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              ref="addWishlistModalCloseButton"
              type="reset"
              class="btn btn-white me-3"
              data-bs-toggle="modal"
              data-bs-target="#create_tracks_wishlist_modal"
            >
              Close
            </button>
            <button
              @click="createNewWishlist"
              ref="submitButtonRef"
              type="submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElNotification } from "element-plus";
import {
  addTracksInWishlist,
  createUserTracksWishlist,
  // updateUserTracksWishlist,
} from "@/api/mongoWishlists.api";
import { capitalizeFirstLetter } from "@/utils/staticHelper";
import { WishlistFundEnum } from "@/common/enums/wishlistFundEnum";

export default defineComponent({
  name: "CreateTracksWishlistModal",
  methods: { capitalizeFirstLetter },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const dataLoading = ref(false);
    const showCreateWishlistForm = ref(false);
    const formRef = ref(null);
    const submitButtonRef = ref(null);
    const wishListModalButton = ref(null);
    const addWishlistModalCloseButton = ref(null);
    const wishlistName = ref("");
    const isPublic = ref(false);
    const fundSlot = ref(false);
    const store = useStore();

    const validationSchema = Yup.object().shape({
      wishlistName: Yup.string().required().label("Wishlist Name"),
    });

    const wishlists = computed(() => {
      return store.getters["WishlistModule/getWishlists"];
    });

    const wishlistStatus = computed(() => {
      return store.getters["WishlistModule/getShowCreateWishlistModal"];
    });

    const newWishlistTracks = computed(() => {
      return store.getters["WishlistModule/getNewWishlistTracks"];
    });

    onMounted(async () => {
      await store.dispatch("WishlistModule/fetchWishlists");
    });

    const showModal = () => {
      wishListModalButton.value.click();
    };

    const addTracksToWishlist = async (wishlist) => {
      try {
        dataLoading.value = true;
        const filteredTracks = newWishlistTracks.value.filter((track) => {
          if (wishlist.wishlistTracks && wishlist.wishlistTracks.length > 0) {
            return !wishlist.wishlistTracks.some(
              (item) => item.trackID === track.trackID
            );
          }
          return true;
        });
        if (filteredTracks.length) {
          const payload = {
            wishlistID: wishlist._id,
            wishlistTracks: filteredTracks,
          };
          await addTracksInWishlist(payload);
          ElNotification({
            title: "Success",
            message: "New tracks added successfully",
            type: "success",
          });
        } else {
          ElNotification({
            title: "Success",
            message: "Tracks Already Exist",
            type: "success",
          });
        }
      } catch (e) {
        console.log(e);
        ElNotification({
          title: "Oops!",
          message: "Error adding tracks to wishlist",
          type: "error",
        });
      } finally {
        dataLoading.value = false;
        addWishlistModalCloseButton.value.click();
        await store.dispatch("WishlistModule/fetchWishlists");
        await store.commit("WishlistModule/SET_NEW_WISHLIST_TRACKS", []);
      }
    };

    const createNewWishlist = async ($event) => {
      $event.preventDefault();
      if (wishlistName.value) {
        try {
          //Disable button
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");

          const payload = {
            isPublic: isPublic.value,
            fundSlot:
              fundSlot.value === true
                ? WishlistFundEnum.ACQUIRED_CATALOGUE_LIST
                : WishlistFundEnum.EQUITY_CATALOGUE_LIST,
            name: wishlistName.value,
            wishlistTracks: newWishlistTracks.value,
          };
          await createUserTracksWishlist(payload);
          ElNotification({
            title: "Success",
            message: "Wishlist added successfully",
            type: "success",
          });
          addWishlistModalCloseButton.value.click();
        } catch (e) {
          console.log(e);
          ElNotification({
            title: "Oops!",
            message: e.response.data.message[0],
            type: "error",
          });
        }
        submitButtonRef.value.removeAttribute("data-kt-indicator");
        submitButtonRef.value.disabled = false;
        showCreateWishlistForm.value = false;
        await store.dispatch("WishlistModule/fetchWishlists");
        await store.commit("WishlistModule/SET_NEW_WISHLIST_TRACKS", []);
      } else {
        formRef.value.setFieldError(
          "wishlistName",
          "Wishlist Name is a required field"
        );
      }
    };

    watch(
      () => wishlistStatus.value,
      async (value) => {
        if (value) {
          await showModal();
          store.commit("WishlistModule/SET_SHOW_CREATE_WISHLIST_MODAL", false);
        }
      },
      { immediate: true }
    );

    return {
      showCreateWishlistForm,
      dataLoading,
      formRef,
      submitButtonRef,
      wishlists,
      wishListModalButton,
      addWishlistModalCloseButton,
      wishlistName,
      isPublic,
      fundSlot,
      validationSchema,
      addTracksToWishlist,
      createNewWishlist,
    };
  },
});
</script>
